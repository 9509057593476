$portal-font-family:  "Roboto", Helvetica, Arial, sans-serif !default;
$portal-font-family-narrow:  "Roboto Condensed", "Roboto", Helvetica, Arial, sans-serif !default;

// Font families
$font-family-sans-serif:  "Roboto", Helvetica, Arial, sans-serif !default;
$font-family-sans-serif-narrow:  "Roboto Condensed", "Roboto", Helvetica, Arial, sans-serif !default;

// Font weights
$lightFont : 300;
$semiNormalFont: 400;
$normalFont : 500;
$boldFont : 700;

$narrowLightFont : 300;
$narrowRegularFont: 400;
$narrowBoldFont : 700;

$defaultAnimationEasing: cubic-bezier(0.250, 0.100, 0.250, 1.000);
$defaultAnimationDuration : 0.5s;
$defaultAnimation: all $defaultAnimationEasing $defaultAnimationDuration;

$inputCheckboxWidth: 55px;

$topBarButtonSize: 32px;

// statusbar
$statusBarHeight: 32px;

//top bar
$topBarHeight: 64px;

//sidebar
$sidebarContainerCollapsedWidth: 100px;

// inputs
$inputLabelHeight: 1.9rem;
$inputLabelFontSize: 1.2rem;
$inputCheckboxWidth: 55px;
$inputRadioWidth: 35px;
$passwordButtonWidth: 36px;

$defaultVertPadding: 0.3em; // used for inputs (relative to font-size)

// Screen size variables
$screen-xs-min: 425px;  // Tiny phones
$screen-sm-min: 576px;  // Small tablets and large smartphones (landscape view)
$screen-md-min: 768px;  // Small tablets (portrait view)
$screen-lg-min: 992px;  // Tablets and small desktops
$screen-xl-min: 1200px; // Large tablets and desktops

// Mixins
@mixin xs { @media (min-width: #{$screen-xs-min}) {@content;} } // Tiny devices
@mixin sm { @media (min-width: #{$screen-sm-min}) {@content;} } // Small devices
@mixin md { @media (min-width: #{$screen-md-min}) {@content;} } // Medium devices
@mixin lg { @media (min-width: #{$screen-lg-min}) {@content;} } // Large devices
@mixin xl { @media (min-width: #{$screen-xl-min}) {@content;} } // Extra large devices


@mixin xs-max { @media (max-width: #{$screen-xs-min}) {@content;} } // Tiny devices
@mixin sm-max { @media (max-width: #{$screen-sm-min}) {@content;} } // Small devices
@mixin md-max { @media (max-width: #{$screen-md-min}) {@content;} } // Medium devices
@mixin lg-max { @media (max-width: #{$screen-lg-min}) {@content;} } // Large devices
@mixin xl-max { @media (max-width: #{$screen-xl-min}) {@content;} } // Extra large devices


//messages
$msgLineHeight: 20px;
$msgContentPaddingHorizontal: 20px;
$msgContentPaddingVertical: 10px;
