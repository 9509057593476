@import '~ngx-smart-modal/ngx-smart-modal.scss';

$dialogZIndex: 100000;


@mixin centeredDialog() {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .nsm-content {
    margin: auto;
    min-height: 0;
  }

  .nsm-body {
    display: flex;
    flex-direction: column;
    min-height: 0;
  }

}

.nsm-overlay-open {
  z-index: 100000 !important;
}

.modal-confirmationDialog {
  .nsm-overlay-open {
    z-index: 100010 !important; // higher than other dialogs
  }
}

.confirmationDialog {
  @include centeredDialog();
}


