@import 'colors.scss';
@import 'variables.scss';

$tooltipJsBG: $mainColor23;
.popper,
.tooltipJs {
  position: absolute;
  z-index: 200000;
  background: $tooltipJsBG;
  color: black;
  max-width: 50vw;
  border-radius: 3px;
  box-shadow: 0 0 2px rgba(0,0,0,0.5);
  padding: 5px 10px;

  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: $semiNormalFont;
  text-align: center;
  transition: opacity 0.5s $defaultAnimationEasing;
  &.hidden {
    display: block !important;
    opacity: 0 !important;
    transition-duration: 0s;
    pointer-events: none;
  }
  &.noWrap {
    white-space: nowrap;
  }

  $tooltipCloseBtnSize: 20px;
  .tooltipCloseBtn {
    width: $tooltipCloseBtnSize;
    height: $tooltipCloseBtnSize;
    border-radius: $tooltipCloseBtnSize;
    color: $mainColor0;
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    &:hover {
      color: $mainColor2;
    }
    .icon {
      line-height: $tooltipCloseBtnSize;
      font-size: 9px;
    }
  }

}
.popper .popper__arrow,
.tooltipJs .tooltip__arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
}

.tooltipJs .tooltip__arrow,
.popper .popper__arrow {
  border-color: $tooltipJsBG;
}

.popper[x-placement^="top"],
.tooltipJs[x-placement^="top"] {
  margin-bottom: 5px;
}
.popper[x-placement^="top"] .popper__arrow,
.tooltipJs[x-placement^="top"] .tooltip__arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;;
  border-bottom-color: transparent !important;;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.popper[x-placement^="bottom"],
.tooltipJs[x-placement^="bottom"] {
  margin-top: 5px;
}
.tooltipJs[x-placement^="bottom"] .tooltip__arrow,
.popper[x-placement^="bottom"] .popper__arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;;
  border-right-color: transparent !important;;
  border-top-color: transparent !important;;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltipJs[x-placement^="right"],
.popper[x-placement^="right"] {
  margin-left: 5px;
  text-align: left;
}
.popper[x-placement^="right"] .popper__arrow,
.tooltipJs[x-placement^="right"] .tooltip__arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;;
  border-top-color: transparent !important;;
  border-bottom-color: transparent !important;;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.popper[x-placement^="left"],
.tooltipJs[x-placement^="left"] {
  margin-right: 5px;
  text-align: left;
}
.popper[x-placement^="left"] .popper__arrow,
.tooltipJs[x-placement^="left"] .tooltip__arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;;
  border-right-color: transparent !important;;
  border-bottom-color: transparent !important;;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltipTemplate {
  display: block;
  &.hidden {
    display: none;
  }
}
